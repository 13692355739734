import {lazy} from "react"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import {registerSW} from "virtual:pwa-register"

registerSW({immediate: true})

window.addEventListener("unhandledrejection", event => {
  if (event.reason === "Request cancelled") {
    event.preventDefault()
  }
})

export const App = lazy(() => import("./App"))
